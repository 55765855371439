import React from 'react';

import ClientSlider from '../../elements/ClientSlider'
import ClientList from '../../elements/ClientList'

const Clients = (props) => {
    return (
        <div className="bodyBlack">
            <div className="w-90">
                <ClientSlider data={props.data}></ClientSlider>
                <ClientList data={props.data}></ClientList>
            </div>
        </div>
    );
};
export default Clients;