import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import slimg from '../images/img25.jpg'

const SlideWrap = styled.div`
    position:relative;
    display:flex;
    height:auto;
    justify-content:center;
    justify-content: center;
    flex-direction:column;
    align-items: center;
`;
const Img = styled.img`
    -webkit-backface-visibility: hidden; 
    position:  relative;
    border-radius:100%;
    transform-style: preserve-3d;
    transition:all 0.4s ease;
    tra-webkit-transitionnsition:all 0.4s ease;
`;

const ClientName = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    line-height: 100%;
    margin: 0% 0 2% 0;
    padding-top: 2%;
    text-align: center;
    color: #4ab9e9;
    
`;
const ClientDesignation = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight:normal;
    line-height: 1.28;
    text-align: center;
    color: #fff;
`;
const ImageDiv = styled.div`
    display:flex;
    height:220px;
    justify-content:center;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: 479px) {
        height:100%;
    }
`;
const Slide = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const HoverShow = () => {
        setHoverState(hoverState => true);
    };
    const Hoverhide = () => {
        setHoverState(hoverState => false);
    };
    return (
        <SlideWrap id={props.id}>
            <ImageDiv >
                <Img src={props.client.clientImage.mediaItemUrl} hovered={hoverState} onMouseEnter={HoverShow} onMouseLeave={Hoverhide} style={{ cursor: "pointer" }}></Img>
            </ImageDiv>
            <div style={{ "height": "70px", "display": "flex", "flexDirection": "column", "justifyContent": "flex-start", "alignItems": "center" }}>
                <ClientName>{props.client.clientName}</ClientName>
                <ClientDesignation>{props.client.clientDesignation}</ClientDesignation>
            </div>
        </SlideWrap>
    );
};

export default Slide;