import React, { useState, useEffect } from 'react';
import Title from '../elements/Title';
import ReadMore from '../elements/ReadMore';
import styled, { css } from 'styled-components';
import ClientDummy from '../images/Client-Logo.png';

const ClientContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: auto;
  padding: 0% 5%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color:white;
  border-radius:20px;
  overflow:hidden;
  position:relative;
`;
const ClientImageBlock = styled.div`
  display: flex;
  width: 100%;
  height: 352px;
  flex-shrink:0;
  justify-content: center;
  align-items: center;
`;
const ClientImage = styled.img`
  -webkit-backface-visibility: hidden; 
  position: relative;
  z-index: 1;
  width: 90%;
  max-width: 90%;
  margin-top: 0px;
  margin-bottom: 0px;
  filter:grayscale(100%);
  transition: all 350ms ease;
`;
const ClientNameBlock = styled.div`
  padding:5% 7%;
  background-color:white;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 0px;
  opacity:0;
  margin-top: 0px;
  margin-bottom: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 350ms ease;
  @media screen and (max-width: 479px) {
    margin-top: -1px;
  }
`;
const ClientNameContainer = styled.div`
    width:100%;
    height:auto;
    margin-bottom:20px;
    max-height:0px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    transform:translateY(-40px) scale(0.5);
    opacity:0;
`;
const ClientWrap = styled.div`
  position: relative;
  display: flex;
  width: 23%;
  height: 472px;
  min-height: auto;
  border-radius:20px;
  overflow:hidden;
  margin: 20px 1%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 1px 1px 20px 0 transparent;
  transition: all 350ms ease;
  @media screen and (max-width: 991px) {
    width: 46%;
    margin: 20px 2%;
  }
  @media screen and (max-width: 767px) {
    width: 48%;
  }
  @media screen and (max-width: 479px) {
    width:98%;
  }
  &:hover{
    justify-content: space-between;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.11);
  }
  &:hover #grey-client-overlay {
        height:0px!important;
  }
  &:hover {
    ${ClientImage} {
        filter:grayscale(0%);
        -webkit-transform:translateY(20px);
        -moz-transform:translateY(20px);
        -ms-transform:translateY(20px);
    }
    ${ClientNameBlock}{
        height:120px;
        opacity:1;  
        
    }
    ${ClientNameContainer}{
      max-height:300px;
      transform:translateY(0px) scale(1);
      opacity:1;
    }
    
  }
`;
const ClientGreyOverlay = styled.div`
  position: absolute;
  left: 0%;
  border-radius:20px;
  top: 0%;
  right: 0%;
  bottom: 0px;
  z-index: auto;
  height: 100%;
  background-color: #f4f4f4;
  opacity: 0.74;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
`;
const ClientName = styled.h1`
    font-family: 'Montserrat', sans-serif;
    line-height:1.2em;
    font-size:25px;
    color:#000;
    font-weight:500;
    text-align:center;
    margin-bottom:10px;
`;
const ClientTile = (props) => {
  const [hoverState, setHoverState] = useState();
  const toggleHover = () => {
    setHoverState(hoverState => !hoverState);
  };
  return (
    <ClientWrap>
      <ClientContainer>
        <ClientImageBlock>
          <ClientImage src={props.client.clientLogo.mediaItemUrl}></ClientImage>
        </ClientImageBlock>
        <ClientNameBlock>
          <ClientNameContainer>
            <ClientName>{props.client.clientName}</ClientName>
            {props.client.projectPost != null ?
              <ReadMore link={"/project/" + props.client.projectPost.slug + "/"} color="#191919">view project</ReadMore>
              :
              ''
            }
          </ClientNameContainer>
        </ClientNameBlock>
        <ClientGreyOverlay id="grey-client-overlay"></ClientGreyOverlay>
      </ClientContainer>


    </ClientWrap>
  );
};

export default ClientTile;