import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import Title from '../elements/Title'
import ClientTile from '../elements/ClientTile'

const ClientListBlock = styled.div`
  margin-top:-120px;
  position:relative;
  @media screen and (max-width: 479px) {
    margin-top: -50px;
  }
`;
const ClientListSection = styled.div`
  display: flex;
  width: 90%;
  height: auto;
  max-height: none;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: transparent;
`;


const ClientList = (props) => {
  return (
    <ClientListBlock>
      <Title weight="bold" size="xl" color="white">clients</Title>
      <ClientListSection>
        {props.data.allWpClient.nodes.map(function (client, index) {
          return (
            <ClientTile client={props.data.allWpClient.nodes[index].client}></ClientTile>
          );
        })}
      </ClientListSection>
    </ClientListBlock>
  );
};

export default ClientList;