import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from 'styled-components'
import Slide from '../elements/ClientSlide'
import Title from '../elements/Title'
import Para from '../elements/Paragraph'
import ReadMore from '../elements/ReadMore'

import Sample from '../images/sample.png'
import slimg from '../images/img25.jpg'

const Featured = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 1.23;
    text-align: left;
    color: #000000;
`;
const SliderBanner = styled.div`
    width:100%;
    height:55vh;
    position:relative;
    background-size:cover;
    background-position: 50% 50%;
    z-index:10;
    @media screen and (min-width: 1920px) {
      height:40vh;
    }
    @media screen and (max-width: 1280px) {
      height:55vh;
    }
    @media screen and (max-width: 991px) {
      height:50vh;
    }
  @media screen and (max-width: 767px) {}
  @media screen and (max-width: 479px) {
    height:960px;
  }
`;
const SliderBannerOpacity = styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    display: flex;
    overflow: visible;
    width: 100%;
    height: 100%;
    padding: 2% 3% 3% 2%;
    flex-direction: column;
    align-items: flex-start;
    border-style: none;
    background-color: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 479px) {
      padding: 5%;
    }
`;
const ViewProjectLink = styled.div`
    position: relative;
    // left: -3rem;
    // top: -4rem;
    z-index: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;   
    justify-content: center;
    @media screen and (max-width: 479px) {
    }
  
`;
const SliderContainer = styled.div`
    position:relative;
    padding-top:120px;
    @media screen and (max-width: 479px) {
      padding-top:80px;
    }
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "65%" }}
      onClick={onClick}
    >
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "65%" }}
      onClick={onClick}
    >
    </div>
  );
}
const lorem = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et";
const Arr = [];
const Filter = (data) => {
  return data.data.allWpClient.nodes.map((node, i) => {
    if (data.data.allWpClient.nodes[i].client.testimonial != null) {
      Arr.push(data.data.allWpClient.nodes[i])
      return
    } else {
      return;
    }
  });
}
const Carousel = (props) => {

  const [activeSlide, setTextState] = useState(0);
  const [nextSlide, setNextSlide] = useState(0);
  Filter(props);

  // for (var i = 0; i < props.data.allWpClient.nodes.length; i++) {
  //   props.data.allWpClient.nodes[i].client.testimonial!==null?
  //   Arr.push(props.data.allWpClient.nodes[i])
  //   :
  //   ''
  // }

  // props.data.allWpClient.nodes.filter(client => client.testimonial!=null).map(filteredClient => (
  //    console.log(filteredClient)
  //   ))


  const settings = {
    dots: false,
    infinite: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: "center",
    className: "client-slider",
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setTextState(activeSlide => current, nextSlide => current + 1),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <SliderContainer>
      <SliderBanner style={{ "backgroundImage": `url(${Arr[activeSlide].client.projectPost.featuredImage.node.mediaItemUrl})` }}>
        <SliderBannerOpacity>
          <Title color="#4ab9e9" width="80%" weight="bold" lineHeight="1.1" size="lg">{Arr[activeSlide].client.projectName}</Title>
          <Para LineHeight="1.32" color="#f5f5f5" zIndex="50">{Arr[activeSlide].client.testimonial}</Para>
          {Arr[activeSlide].client.projectPost != null ?
            <ViewProjectLink>
              <ReadMore link={"/project/" + Arr[activeSlide].client.projectPost.slug + "/"} align="right" color="#fff" top="2" zIndex="120">view project</ReadMore>
            </ViewProjectLink>
            : ''
          }
          {/* <ReadMore align="right" color="#fff" top="2" zIndex="120">view project</ReadMore>                                        */}
        </SliderBannerOpacity>
      </SliderBanner>

      <Slider {...settings}>

        {Arr.map(function (client, index) {
          return <Slide client={Arr[index].client} key={index} id={'client' + index}></Slide>
        })}
      </Slider>
    </SliderContainer>
  );
};

export default Carousel;