import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Clients from "../views/Clients/Clients"
const ClientsPage = ({ data }) => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Our Clients " url="https://www.kwazi.in/clients" />
    <Clients data={data}></Clients>
  </Layout>
)

export default ClientsPage
export const query = graphql`
  query{
    allWpClient(sort: {fields: client___associatedCaseStudy}) {
      nodes {
        client {
          clientName
          testimonial
          clientDesignation
          projectPost {
            ... on WpPost {
              id
              title
              slug
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
              summary {
                summary
              }
            }
          }
          clientImage {
            mediaItemUrl
          }
          clientLogo {
            mediaItemUrl
          }
          projectName
        }
      }
    }
  }
`